import React, { useState } from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter
} from "reactstrap"
import { Star } from 'react-feather'
import Rating from 'react-rating'
import api from '@api'
import { CreateRatingCommand } from '@api/api'
import { set } from 'lodash'


const StarRatingItem: React.FC<{ title: string, onChangeCallback: (rate: number) => void, currRating?: number }> = ({
    title, onChangeCallback, currRating
}) => {
    return (
        <div className="d-flex justify-content-between align-items-center w-100 my-1">
            <span>{title}</span>
            <Rating
                emptySymbol={<Star className="mx-1" />}
                fullSymbol={<Star className="mx-1 warning" />}
                onChange={r => onChangeCallback(r)}
                initialRating={currRating}
            />
        </div>
    );
};

const initialForm: CreateRatingCommand = new CreateRatingCommand();

const RatingModal: React.FC<{ show: boolean, hide: () => void }> = ({
    show, hide
}) => {
    const [form, setform] = useState(initialForm)

    function handleForm(prop: string, value: number | string): void {
        let newForm = Object.assign({}, form);
        set(newForm, prop, value)
        setform(newForm);
    }

    const handleSubmit: () => void = () => {
        api.common_Rating_Add(form).then(() => hide())
    }

    return (
        <Modal isOpen={show} toggle={() => hide()} className="modal-dialog-centered modal-md">
            <ModalHeader toggle={() => hide()} className="bg-primary">Deixe-nos a sua avaliação</ModalHeader>
            <ModalBody className="modal-dialog-centered d-flex flex-column">

                <div className="w-100">
                    <StarRatingItem
                        title="Geral"
                        onChangeCallback={(r) => handleForm('geral', r)}
                        currRating={form.geral}
                    />

                    <StarRatingItem
                        title="Conteúdo"
                        onChangeCallback={(r) => handleForm('conteudo', r)}
                        currRating={form.conteudo}
                    />

                    <StarRatingItem
                        title="Design"
                        onChangeCallback={(r) => handleForm('design', r)}
                        currRating={form.design}
                    />

                    <StarRatingItem
                        title="Performance"
                        onChangeCallback={(r) => handleForm('performance', r)}
                        currRating={form.performance}
                    />

                    <hr className="my-2" />

                    <Input
                        type="textarea"
                        defaultValue=""
                        placeholder="Sugestões ..."
                        onChange={(e) => handleForm('sugestoes', e.target.value)}
                    />
                </div>

            </ModalBody>

            <ModalFooter>
                <Button color="flat-primary" className="mr-1" onClick={() => hide()}>Mais tarde</Button>
                <Button color="primary" onClick={() => handleSubmit()}>Submeter</Button>
            </ModalFooter>
        </Modal>
    )
}

export default RatingModal

