import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { Button } from 'reactstrap';
import { ArrowUp } from 'react-feather';
import classnames from 'classnames';
import { RoleCheck } from '@components';

const Footer = props => {
    let footerTypeArr = ['sticky', 'static', 'hidden'];
    return (
        <footer
            className={classnames('footer footer-light', {
                'footer-static': props.footerType === 'static' || !footerTypeArr.includes(props.footerType),
                'd-none': props.footerType === 'hidden'
            })}
        >
            <p className="mb-0 clearfix">
                <span className="float-md-left d-block d-md-inline-block mt-25">
                    COPYRIGHT © {new Date().getFullYear()}
                    <a href="https://www.empifarma.pt" target="_blank" rel="noopener noreferrer">
                        Empifarma.
                    </a>
                    Todos os direitos reservados
                    <RoleCheck roles={['PARCEIRO']}>
                        {' | '}
                        <a
                            href="https://emppub.blob.core.windows.net/public/img/Avaliac%CC%A7a%CC%83o%20Fornecedores%20de%20Produtos.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Avaliação de Fornecedores de Produtos
                        </a>
                        {' | '}
                        <a
                            href="https://emppub.blob.core.windows.net/public/img/POLI%CC%81TICA%20DA%20QUALIDADE%20Ed9.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Política da Qualidade
                        </a>
                    </RoleCheck>
                    <RoleCheck roles={['CLIENTE']}>
                        {' | '}
                        <a
                            href="https://emppub.blob.core.windows.net/public/img/Politica_Comercial_2024.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Política Comercial
                        </a>
                    </RoleCheck>
                </span>
            </p>
            {props.hideScrollToTop === false ? (
                <ScrollToTop showUnder={160}>
                    <Button color="primary" className="btn-icon scroll-top">
                        <ArrowUp size={15} />
                    </Button>
                </ScrollToTop>
            ) : null}
        </footer>
    );
};

export default Footer;
