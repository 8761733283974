const MESES = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

const SEGMENTOS_PARCEIROS: { [key: string]: string } = {
    MAG: 'Magium',
    TFO: 'Cross Docking',
    PARC: 'DTP',
    GENE: 'Generalista',
    TRI: 'Tripartidos',
    VIAV: 'Via Verde',
    ARM: 'Armazenista'
};

enum TIPO_ENTIDADE {
    CLIENTE = 'CLIENTE',
    FORNECEDOR = 'FORNECEDOR',
    GRUPO = 'GRUPO',
    DEFAULT = 'DEFAULT'
}

const ROLES = {
    PrivilegedOnly: [
        'CLIENTE_ADMIN',
        'CLIENTE_COMERCIAL',
        'GRUPO_ADMIN',
        'GRUPO_COMERCIAL',
        'PARCEIRO_ADMIN',
        'PARCEIRO_COMERCIAL',
        'DEFAULT_ADMIN'
    ]
};

export { MESES, SEGMENTOS_PARCEIROS, TIPO_ENTIDADE, ROLES };
