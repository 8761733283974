import React, { memo } from 'react';
import { Spinner } from 'reactstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

interface Props {
    blocking?: boolean;
    className?: string;
    children?: JSX.Element | (JSX.Element | undefined)[] | null | undefined;
    renderChildren?: boolean;
}

const BlockUiWrapper = (props: Props) => {
    return (
        <BlockUi
            blocking={props.blocking}
            loader={<Spinner type="grow" color="primary" />}
            className={props.className}
            renderChildren={props.renderChildren}
        >
            {props.children}
        </BlockUi>
    );
};

export default memo(BlockUiWrapper);
