import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const RoleCheck = (props) => {
    const { login } = useSelector(({ auth }) => auth)
    const userRoles = login?.userRole || []

    if (userRoles.some(r => props.roles.includes(r))) {
        return props.children
    }
    else {
        return ""
    }
}

RoleCheck.propTypes = {
    roles: PropTypes.array.isRequired,
    children: PropTypes.any
}

export default RoleCheck

