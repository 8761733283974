import calcPVF from './calcPVF'

export default function calcRentabilidade(a, c, q, p = 0, r) {
    try {
        let pvf = calcPVF(a, c, q, p)

        let rent = r - pvf

        return (rent < 0 ? 0 : rent) * q
    }
    catch (e) {
        console.log(a, c, q, r)
        return 0
    }
}