import React from 'react';
import { Button, CustomInput, FormGroup } from 'reactstrap';
import { Formik, Form, FieldArray } from 'formik';
import api from '@api/v2';
import { IUserVmV2, RoleAssignmentForm, RoleVmV2 } from '@api/v2/api';
import { toast } from 'react-toastify';

interface Props {
    managedUserRoles: RoleVmV2[];
    user: IUserVmV2;
}

const RoleAssignmentUpdateForm = (props: Props) => {
    return (
        <Formik
            initialValues={{
                roles: props.user.roles || []
            }}
            onSubmit={values =>
                api
                    .roles_CreateAssignment(
                        new RoleAssignmentForm({
                            userId: props.user.id as string,
                            roles: values.roles
                        })
                    )
                    .then(() => window.location.reload())
                    .catch(err => {
                        console.log(err);
                        toast.error(err?.message ?? 'Não foi possível processar o pedido');
                    })
            }
        >
            {({ values }) => {
                return (
                    <Form>
                        <FormGroup>
                            <FieldArray name="roles">
                                {({ remove, push }) => (
                                    <ul className="list-group list-group-flush">
                                        {props.managedUserRoles.map((permissao, index) => (
                                            <li key={index} className="list-group-item d-flex justify-content-between">
                                                {permissao.friendlyName}
                                                <CustomInput
                                                    id={permissao.name as string}
                                                    type="checkbox"
                                                    className="custom-control-primary"
                                                    defaultChecked={values.roles.includes(permissao.name as string)}
                                                    disabled={['CLIENTE', 'PARCEIRO', 'CLIENTE_GRUPO'].includes(
                                                        permissao.name as string
                                                    )}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            push(permissao.name as string);
                                                        } else {
                                                            remove(values.roles.indexOf(permissao.name as string));
                                                        }
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </FieldArray>
                        </FormGroup>

                        <Button color="primary" type="submit" className="float-right">
                            Submeter
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RoleAssignmentUpdateForm;
