import { history } from "../../../history"
import authService from 'utility/auth/authService'

export const changeRole = role => {
    return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const loginWithJWT = user => {
    return dispatch => {
        authService.login(user.username, user.password)
            .then(user => {
                dispatch(setUserData(user))
            })
            .catch(() => {
                dispatch({ type: "LOGIN_FAILED" })
            })
    }
}

export const setUserData = user => {
    return dispatch => {
        dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { user, loggedInWith: "jwt" }
        })

        dispatch({ type: "CHANGE_ROLE", userRole: user.roles })

        if (history.location.pathname !== "/" && history.location.pathname !== "/login") {
            history.push(history.location.pathname)
        }
        else {
            history.push("/")
        }
    }
}

export const logoutWithJWT = () => {
    return dispatch => {
        authService.logout()
        dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
        history.push("/login")
    }
}
