import React, { useState, useEffect } from 'react'
import {
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap"
import { Clock } from 'react-feather'
import Countdown from "react-countdown-now"
import { differenceInMilliseconds } from 'date-fns'

import api from '@api'

const renderTimer = ({ days, hours, minutes, seconds }) => {
    return (
        <React.Fragment>
            {/* <div className="clockCard px-1">
                <p>{days}</p>
                <p className="bg-amber clockFormat lead px-1 black"> Days </p>
            </div> */}
            <div className="clockCard px-1">
                <p>{hours}</p>
                <p className="bg-amber clockFormat lead px-0 black">Horas</p>
            </div>
            <div className="clockCard px-1">
                <p>{minutes}</p>
                <p className="bg-amber clockFormat lead px-0 black">Minutos</p>
            </div>
            <div className="clockCard px-1">
                <p>{seconds}</p>
                <p className="bg-amber clockFormat lead px-0 black">Seg.</p>
            </div>
        </React.Fragment>
    )
}

const renderTimerV2 = ({ days, hours, minutes, seconds }) => {
    return (
        <span className={hours <= 6 ? "danger" : ""}>
            {hours}h {minutes}m {seconds}s
        </span>
    )
}

function CountdownEncomendas(props) {
    const [horaLimite, setHoraLimite] = useState();

    useEffect(() => {
        api.distribuicao_GetHoraLimiteEncomenda().then(data => setHoraLimite(new Date(data)))
    }, [])

    if (!horaLimite) return null

    return (
        <UncontrolledDropdown tag="li" className={"dropdown-user nav-item my-auto " + props.className}>
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name text-bold-600">
                        <Countdown
                            date={Date.now() + differenceInMilliseconds(horaLimite, Date.now())}
                            renderer={renderTimerV2}
                        />
                    </span>
                    <span className="user-status">Limite Encomenda</span>
                </div>
                <span><Clock size="20" /></span>
            </DropdownToggle>
        </UncontrolledDropdown>
    )
}

export default CountdownEncomendas