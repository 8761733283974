import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format';
import { Minus } from 'react-feather'

function FormatPercentage(props) {
    if (props.showMinus && props.value === 0) {
        return <Minus size={14} />
    }

    return (
        <NumberFormat
            suffix={" %"}
            decimalSeparator={","}
            displayType="text"
            value={props.value}
            decimalScale={2}
            className={props.className ? props.className : ""}
        />
    )
}

FormatPercentage.defaultProps = {
    showMinus: false
};

FormatPercentage.propTypes = {
    value: PropTypes.number,
    className: PropTypes.string,
    showMinus: PropTypes.bool
}

export default FormatPercentage