import React, { useEffect, useState } from "react"
import {
    Card,
    CardHeader,
    CardBody,
} from "reactstrap"
import avatarImg from "assets/img/portrait/small/avatar-s-27.jpg"
import { Phone, Mail } from "react-feather"
import api from '@api'
import { WidgetComercialAssociadoDto } from "@api/api"



const Render: React.FC<{ comercial: WidgetComercialAssociadoDto | undefined, themeColor: string }> = ({ comercial, themeColor }) => {
    return (
        <Card>
            <CardHeader className="mx-auto">
                <div className="avatar avatar-xl box-shadow-2">
                    <img src={comercial?.cdU_UrlImagem || avatarImg} alt="fotoComercial" />
                </div>
            </CardHeader>
            <CardBody className="text-center">
                <h4>{comercial?.nome}</h4>
                <p>O seu contacto</p>
                <hr />
                <div className="card-btns d-flex justify-content-between">
                    <div className=""><Mail size="15" className={themeColor} /><span className="ml-50 align-middle">{comercial?.email}</span></div>
                    <div className=""><Phone size="15" className={themeColor} /><span className="ml-50 align-middle">{comercial?.telemovel}</span></div>
                </div>
            </CardBody>
        </Card>
    )
}

const ComercialAssociadoV2: React.FC<{ isMagium: boolean }> = ({
    isMagium
}) => {
    const [state, setState] = useState<WidgetComercialAssociadoDto[]>()
    const themeColor = isMagium ? "danger" : "primary"

    useEffect(() => {
        api.widgets_Comercial().then(data => {
            setState(data)
        })
    }, [])

    return (
        <Render comercial={state?.filter((d, idx) => idx === (isMagium ? 1 : 0))[0]} themeColor={themeColor} />
    )
}

export default React.memo(ComercialAssociadoV2)

