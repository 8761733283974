import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import * as Icon from 'react-feather';
import { ProximaEntrega, ShoppingCartCount, CountdownEncomendas, RoleCheck } from '@components';
import { history } from '../../../history';
import { ROLES } from '@components/consts';

const handleNavigation = (e, path) => {
    e.preventDefault();
    history.push(path);
};

const UserDropdown = props => {
    return (
        <DropdownMenu right>
            <RoleCheck roles={['CLIENTE_GESTOR']}>
                <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, '/perfil')}>
                    <Icon.User size={14} className="mr-50" />
                    <span className="align-middle">Perfil</span>
                </DropdownItem>
                <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, '/comunicacoes')}>
                    <Icon.Mail size={14} className="mr-50" />
                    <span className="align-middle">Comunicações</span>
                </DropdownItem>
                <DropdownItem divider />
            </RoleCheck>
            <RoleCheck roles={ROLES.PrivilegedOnly}>
                <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, '/gestao')}>
                    <Icon.Settings size={14} className="mr-50" />
                    <span className="align-middle">Gestão</span>
                </DropdownItem>
                <DropdownItem divider />
            </RoleCheck>
            <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, '/conta/reset-password')}>
                <Icon.Lock size={14} className="mr-50" />
                <span className="align-middle">Alterar Password</span>
            </DropdownItem>
            <DropdownItem
                tag="a"
                href="/login"
                onClick={e => {
                    e.preventDefault();
                    return props.logoutWithJWT();
                }}
            >
                <Icon.Power size={14} className="mr-50" />
                <span className="align-middle">Log Out</span>
            </DropdownItem>
        </DropdownMenu>
    );
};

class NavbarUser extends React.PureComponent {
    render() {
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <RoleCheck roles={['CLIENTE']}>
                    <ShoppingCartCount />

                    <CountdownEncomendas className="d-none d-lg-flex" {...this.props} />

                    <ProximaEntrega {...this.props} handleNavigation={handleNavigation} className="d-none d-lg-flex" />
                </RoleCheck>

                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav d-md-flex d-none">
                            <span className="user-name text-bold-600">{this.props.userInfo.info[0]}</span>
                            <span className="user-status">{this.props.userInfo.info[1]}</span>
                        </div>
                        <span data-tour="user">
                            <img src={this.props.userImg} className="round" height="40" width="40" alt="avatar" />
                        </span>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </UncontrolledDropdown>
            </ul>
        );
    }
}
export default NavbarUser;
