import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap"
import { Truck } from 'react-feather'
import classnames from 'classnames'
import api from '@api'


function ProximaEntrega(props) {
    const [state, setState] = useState()

    useEffect(() => {
        api.distribuicao_GetProximaEntrega().then(data => setState(data))
    }, [])

    return (
        <UncontrolledDropdown
            tag="li"
            className={classnames("dropdown-user nav-item my-auto", props.className)}
            onClick={e => props.handleNavigation(e, "/entregas")}
        >
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name text-bold-600">{state}</span>
                    <span className="user-status">Próx. Entrega</span>
                </div>
                <span>
                    {/* <img
                        src={props.userImg}
                        className="round"
                        height="40"
                        width="40"
                        alt="avatar"
                    /> */}
                    <Truck size="20" />
                </span>
            </DropdownToggle>
        </UncontrolledDropdown>
    )
}

ProximaEntrega.propTypes = {
    className: PropTypes.string
}

export default ProximaEntrega

