const getGrowthRate = (before?: number, after?: number) => {
    before = before || 0;
    after = after || 0;

    if (before === 0) {
        return before;
    }
    return ((after - before) / before) * 100;
};

export default { getGrowthRate };
