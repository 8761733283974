import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import { Download, XOctagon } from 'react-feather';
import { downloadPDF } from '@components';
import api from '@api';

export default function DownloadPdfButton(props) {
    const [state, setState] = useState('');

    const handleDownload = (id, filename, modulo) => {
        setState('loading');

        api.common_Pdf(id, modulo)
            .then(data => {
                if (!data) {
                    setState('error');
                } else {
                    setState('');
                    downloadPDF(data, filename);
                }
            })
            .catch(() => {
                setState('error');
            });
    };

    if (!props.id) return '';

    return (
        <Button.Ripple
            color="flat-primary"
            disabled={state === 'loading' || state === 'error'}
            onClick={() => {
                handleDownload(props.id, props.filename, props.modulo);
            }}
        >
            {state === '' && <Download size={15} />}
            {state === 'loading' && <Spinner className="mr-25" size="sm" />}
            {state === 'error' && <XOctagon size={15} />}
        </Button.Ripple>
    );
}

DownloadPdfButton.propTypes = {
    id: PropTypes.string,
    filename: PropTypes.string,
    modulo: PropTypes.string
};
