import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import api from '@api';
import { useDispatch } from 'react-redux';
import * as loginActions from 'redux/actions/auth/loginActions';
import { CloudOff } from 'react-feather';

const ApiMonitorModal = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => check(), 30 * 1000);
        return () => clearInterval(interval);
    }, []);

    const check = () => {
        api.account_Profile()
            .then(() => {
                if (show) {
                    setShow(false);
                }
            })
            .catch(err => {
                if (err.message === 'Network Error') {
                    setShow(true);
                }
                if (err.status === 401) {
                    dispatch(loginActions.logoutWithJWT());
                }
            });
    };

    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} className="modal-dialog-centered modal-md">
            <ModalHeader className="bg-danger">Sem ligação</ModalHeader>
            <ModalBody className="modal-dialog-centered d-flex flex-column">
                <CloudOff size="64" className="my-2" />
                <h2>Ligação ao servidor interrompida</h2>
                <h5>Aguarde por favor</h5>
                <Spinner type="grow" color="danger" className="my-2" />
            </ModalBody>
        </Modal>
    );
};

export default React.memo(ApiMonitorModal);
