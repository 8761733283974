import React from 'react'
import PropTypes from 'prop-types'
import {
    Col, Row, FormGroup, Input, Spinner, Card, CardBody,
    CardImg, Badge, Button
} from 'reactstrap'
import { FormatCurrency } from '@components'
import NumericInput from "react-numeric-input"
import * as Icon from 'react-feather'
import InfiniteScroll from 'react-infinite-scroll-component';
import "assets/scss/pages/product-infinite-scroll.scss"

const ProductComponent = (props) => {
    const { product, shopcartItem } = props

    if (!product) {
        return null
    }

    return (
        <Card className="ecommerce-card">
            <div className={"card-content " + (shopcartItem ? "in-cart" : "")}>
                <CardBody>
                    <div className="item-wrapper">
                        {/* <div className="item-rating">
                            <Badge color="primary" className="badge-md">
                                <span className="mr-50 align-middle">4</span>
                                <Icon.Star size={14} />
                            </Badge>
                        </div> */}
                        <div className="product-price">
                            <h6 className="item-price">{product.price}</h6>
                        </div>
                    </div>
                    <div className="item-title">
                        <span className="item-name">{product.descricao}</span>
                        <div className="items-extra">
                            <span className="mr-1"><Icon.Circle className={product.stock.classe} size={11} /></span>
                            <span className="mr-1">{product.artigo}</span>
                            <span className="item-dci mr-1">{product.dci}</span>
                            <span className="item-description">Benefício: <FormatCurrency value={0} className="success" /></span>
                        </div>
                    </div>
                    <div className="item-desc">
                        <span className="item-description">PVP: <FormatCurrency value={product.pvp} /></span>
                        <span className="item-description">PVA: <FormatCurrency value={product.pva} /></span>
                        {product.stock.Grupo > 0 && <span className="item-description">Grupo: {product.stock.grupo} un.</span>}
                        {product.tipoDesconto &&
                            <span className={"item-discount " + "tipodesc-color-" + product.tipoDesconto}>
                                {product.desconto} {product.tipoDesconto}
                            </span>}
                    </div>
                </CardBody>
                <div className="item-options text-center">
                    <div className="item-wrapper">
                        <div className="product-price item-price h4">
                            PVF: <FormatCurrency value={product.pvf * (shopcartItem ? shopcartItem : 1)} className="" />
                        </div>
                    </div>
                    <div className="cart-change">
                        <NumericInput
                            className="form-control"
                            min={0}
                            max={100}
                            value={shopcartItem}
                            noStyle
                            placeholder="Qtd."
                            onChange={val => props.onQuantityChange(product.artigo, val)}
                        />
                    </div>
                </div>
            </div>
        </Card>
    )
}
ProductComponent.propTypes = {
    product: PropTypes.object,
    onQuantityChange: PropTypes.func
}

function ProductsInfiniteScroll(props) {
    return (
        <div className="product-infite-scroll">
            <div className="ecommerce-app">
                <div className="list-view">
                    {props.isLoading && <Spinner type="grow" color="primary" className="mx-auto mb-2" />}
                    <InfiniteScroll
                        dataLength={props.data.length}
                        hasMore={true}
                        next={props.fetchMoreData}
                        style={{
                            overflowX: 'hidden'
                        }}
                    >
                        <Row className="p-0">
                            {
                                props.data.map((prd, i) =>
                                    <Col key={i} lg="6" md="12">
                                        <ProductComponent
                                            product={prd}
                                            shopcartItem={props.selectedData[prd.artigo]}
                                            onQuantityChange={props.onQuantityChange}
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

ProductsInfiniteScroll.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    onQuantityChange: PropTypes.func,
    fetchMoreData: PropTypes.func,
    selectedData: PropTypes.object
}

export default ProductsInfiniteScroll

