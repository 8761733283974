import api from '@api';
import { tracking } from '@components';

export const open = () => {
    return (dispatch) => {
        dispatch({ type: 'FT_OPEN' });
    };
};
export const close = () => {
    return (dispatch) => {
        dispatch({ type: 'FT_CLOSE' });
    };
};
export const showResumo = (flag) => {
    return (dispatch) => {
        dispatch({ type: 'FT_SHOW_RESUMO', payload: flag });
    };
};
export const showConfirmation = (flag) => {
    return (dispatch) => {
        dispatch({ type: 'FT_SHOW_CONFIRMATION', payload: flag });
    };
};
export const upsert = (item) => {
    return (dispatch) => {
        if (item.quantidade === 0 || !item.quantidade) {
            dispatch({ type: 'FT_DELETE_ITEM', payload: item.artigo });
            tracking.removeFromCart(item.artigo);
        } else {
            if (item.quantidade > item.stock.primario + item.stock.secundario) {
                item.quantidade = item.stock.primario + item.stock.secundario;
            }

            dispatch({ type: 'FT_UPSERT_ITEM', payload: item });
            tracking.addToCart(item.artigo, item.quantidade);
        }
    };
};
export const deleteFromCart = (artigo) => {
    return (dispatch) => {
        dispatch({ type: 'FT_DELETE_ITEM', payload: artigo });
        tracking.removeFromCart(artigo);
    };
};
export const deleteAllFromCart = () => {
    return (dispatch) => {
        dispatch({ type: 'FT_DELETE_ALL' });
    };
};
export const searchByParceiro = (id, isMagiumOnly = false) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;

        if (isMagiumOnly) {
            dispatch(setData({ ...form, fornecedores: [id], query: 'magium-only' }));
        } else {
            dispatch(setData({ ...form, fornecedores: [id] }));
        }
    };
};

export const searchByParceiros = (ids, isMagiumOnly = false) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;

        if (isMagiumOnly) {
            dispatch(setData({ ...form, fornecedores: ids, query: 'magium-only' }));
        } else {
            dispatch(setData({ ...form, fornecedores: ids }));
        }
    };
};

export const searchByKey = (key) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;
        dispatch(setData({ ...form, query: key }));
    };
};
export const searchByPromocao = (id) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;
        dispatch(setData({ ...form, promocaoId: [id] }));
        tracking.promocaoClick(id);
    };
};
export const setData = (newForm) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;

        if (
            newForm.query !== form.query ||
            newForm.fornecedores !== form.fornecedores ||
            newForm.promocaoId !== form.promocaoId
        ) {
            const data = await api.produtos_Get(
                newForm.query,
                0,
                newForm.rows,
                newForm.fornecedores,
                newForm.promocaoId
            );

            dispatch({
                type: 'FT_SET_DATA',
                payload: {
                    data: data ? data : [],
                    form: {
                        ...newForm,
                        offset: 0,
                    },
                },
            });
        }
    };
};
export const setDataDirectly = (data) => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;

        dispatch({
            type: 'FT_SET_DATA',
            payload: {
                data: [data],
                form: {
                    ...form,
                    offset: 0,
                },
            },
        });
    };
};
export const addData = () => {
    return async (dispatch, getState) => {
        const { form } = getState().fastTrack;
        const data = await api.produtos_Get(form.query, form.offset + form.rows, form.rows, form.fornecedores);

        if (data) {
            dispatch({ type: 'FT_ADD_DATA', payload: data });
        }
    };
};
export const submit = () => {
    return async (dispatch, getState) => {
        dispatch({ type: 'FT_IS_SUBMITTING', payload: true });

        const { cart } = getState().fastTrack;

        try {
            await api.produtos_Encomendar(cart.map((i) => ({ ...i })));
            dispatch({ type: 'FT_ORDER_SUBMITTED' });
        } catch (error) {
            console.error(error);
        }
    };
};

// export const updateCarrinho = (artigo, quantidade) => {
//     return dispatch => {

//         if (quantidade === 0) {
//             dispatch({ type: "DELETE_CARRINHO", payload: { artigo } })
//         }
//         else {
//             dispatch({ type: "UPDATE_CARRINHO", payload: { artigo, quantidade } })
//         }

//     }
// }
// export const deleteCarrinho = (artigo) => {
//     return dispatch => {
//         dispatch({ type: "DELETE_CARRINHO", payload: { artigo } })
//     }
// }
// export const cleanCarrinho = () => {
//     return dispatch => {
//         dispatch({ type: "CLEAN_CARRINHO" })
//     }
// }

// case "FT_OPEN":
//     return action.payload
// case "FT_CLOSE":
//     return action.payload
// case "FT_SET_SEARCH":
//     return action.payload
// case 'FT_UPSERT_ITEM':
//     return null
// case 'FT_DELETE_ITEM':
//     return null
// case 'FT_DELETE_ALL':
//     return null
// default:
//     return state
