import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import api from '@api';

const get = async inputValue => {
    if (inputValue.length >= 3) {
        const data = await api.common_GruposByQuery(inputValue);
        return data;
    }
};

const promiseOptions = inputValue =>
    new Promise(resolve => {
        resolve(get(inputValue));
    });

function GrupoSearchBox(props) {
    return (
        <div className={classnames(props.classes, 'w-100')}>
            <AsyncSelect
                isClearable={false}
                cacheOptions={false}
                defaultOptions={false}
                loadOptions={inputValue => promiseOptions(inputValue)}
                onChange={event => props.onChange(event)}
                placeholder={props.placeholder ? props.placeholder : 'Selecione o grupo...'}
                ref={props.reference}
                styles={{
                    menuList: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    }),
                    menu: styles => ({ ...styles, zIndex: 1000 })
                }}
                isDisabled={props.isDisabled}
                noOptionsMessage={() => 'Sem Dados'}
            />
        </div>
    );
}

GrupoSearchBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    reference: PropTypes.object,
    defaultInputValue: PropTypes.string,
    isDisabled: PropTypes.bool,
    classes: PropTypes.string
};

export default GrupoSearchBox;
