import React from 'react'
import { Row, UncontrolledTooltip } from 'reactstrap'
import classnames from "classnames"
import { FormatCurrency, calcPVF, calcStockProximoPeriodo, calcRentabilidade, calcFee } from '@components'
import NumericInput from "react-numeric-input"
import FormatPercentage from '@components/common/FormatPercentage'
import { truncate } from 'lodash'
import { Award } from 'react-feather'

function ProdutoFastTrackItem(props) {
    const { item, fastTrackItem, isTableFormat } = props;
    const condicoes = item.condicoes.filter(c => c.tipoDesconto !== "N")

    const handleChange = (newQty) => {
        props.onChange(item, newQty)
    }

    const isCondicaoEfectiva = (min, max) => {
        let q = fastTrackItem?.quantidade || 1
        return q >= min && q <= max
    }

    return (
        <div className={classnames("suggestion-item border-bottom product-item " + props.className, {
            "in-cart": fastTrackItem
        })}
            onClick={e => e.stopPropagation()}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div className="item-container d-flex">
                    <div className="item-info">

                        <p className="item-descricao">{item.descricao}</p>

                        <div className="item-sub-info">

                            <span id={"stock-" + item.artigo} className={classnames("item-stock", "bg-" + item.stock.classe)}></span>
                            <UncontrolledTooltip placement="bottom" target={"stock-" + item.artigo}>{item.stock.descricao}</UncontrolledTooltip>

                            <span className="item-artigo">{item.artigo}</span>

                            {item.dci && <span className="item-dci">{truncate(item.dci, { length: 20 })}</span>}

                            <span className="item-condicoes">
                                {condicoes.map((c, idx) =>
                                    <span
                                        key={idx}
                                        className={classnames({
                                            "condicao-nao-efectiva": !isCondicaoEfectiva(c.bonificacao[0], c.bonificacao[1])
                                        })}
                                    >
                                        <small id={"tipodesc-" + item.artigo} className={"font-weight-bold tipodesc-" + c.tipoDesconto}>{c.tipoDesconto}</small>
                                        <UncontrolledTooltip placement="bottom" target={"tipodesc-" + item.artigo}>{props.tipoDescDescription[c.tipoDesconto]}</UncontrolledTooltip>
                                        <small className="item-condicoes-desconto font-weight-bold">{c.descontoFormatted}</small>
                                        {(item.stock.grupo > 0 && c.tipoDesconto === "G") && <small className="item-stock-grupo">({item.stock.grupo} un.)</small>}
                                    </span>)}
                            </span>

                        </div>

                        <span className="mr-1">PVP: <FormatCurrency value={item.pvp} showMinus /></span>

                        <span className="mr-1">PVA: <FormatCurrency value={item.pva} /></span>

                        <span className="mr-1">IVA: <FormatPercentage value={item.taxaIva} /></span>

                        <span className="mr-1">Fee: <FormatPercentage value={calcFee(item.artigo, item.condicoes, fastTrackItem?.quantidade || 1)} /></span>

                        {item.pvpSemIva > 0 && <span className="mr-1">Rentab: <FormatCurrency value={calcRentabilidade(item.artigo, item.condicoes, fastTrackItem?.quantidade || 1, item.pva, item.pvpSemIva)} showMinus className="text-success text-bold-600" /></span>}

                        <p className="info mb-0">{calcStockProximoPeriodo(fastTrackItem?.quantidade || 0, item.stock.primario, item.stock.secundario)}</p>
                    </div>
                </div>
                <div className="meta-container d-flex flex-column flex-sm-row align-middle">
                    <div className="item-pvf d-flex flex-column align-items-center">
                        <span className="item-pvf-unitario">P.Unit: <FormatCurrency value={calcPVF(item.artigo, item.condicoes, fastTrackItem?.quantidade || 1, item.pva, false)} /></span>
                        {fastTrackItem?.quantidade > 0 && <small className="item-pvf-total">Total: <FormatCurrency value={calcPVF(item.artigo, item.condicoes, fastTrackItem.quantidade, item.pva, true)} /></small>}
                    </div>
                    <NumericInput
                        className="form-control item-quantidade-input mx-auto"
                        min={0}
                        max={item.stock.primario + item.stock.secundario}
                        value={fastTrackItem?.quantidade}
                        onChange={handleChange}
                        noStyle
                        placeholder="Qtd."
                        onKeyDown={(e) => props.onKeyDown(e, item)}
                        disabled={!item.stock.disponivel}
                    />
                </div>
            </div>
        </div>
    )
}

ProdutoFastTrackItem.propTypes = {

}

export default ProdutoFastTrackItem





// <div className={classnames("suggestion-item border-bottom product-item " + props.className, {
//     "in-cart": fastTrackItem
// })}
//     onClick={e => e.stopPropagation()}
// >
//     <div className="d-flex justify-content-between align-items-center">
//         <div className="item-container d-flex">
//             <div className="item-info">

//                 <p className="item-descricao">{item.descricao}</p>

//                 <div className="item-sub-info">

//                     <span id={"stock-" + item.artigo} className={classnames("item-stock", "bg-" + item.stock.classe)}></span>
//                     <UncontrolledTooltip placement="bottom" target={"stock-" + item.artigo}>{item.stock.descricao}</UncontrolledTooltip>

//                     <span className="item-artigo">{item.artigo}</span>

//                     <span className="item-dci">{truncate(item.dci, { length: 35 })}</span>

//                     <span className="item-condicoes">
//                         {condicoes.map((c, idx) =>
//                             <span key={idx}>
//                                 <small id={"tipodesc-" + item.artigo} className={"font-weight-bold mr-1 tipodesc-" + c.tipoDesconto}>{c.tipoDesconto}</small>
//                                 <UncontrolledTooltip placement="bottom" target={"tipodesc-" + item.artigo}>{props.tipoDescDescription[c.tipoDesconto]}</UncontrolledTooltip>
//                                 <small className="font-weight-bold">{c.descontoFormatted}</small>
//                             </span>)}
//                     </span>

//                 </div>

//                 <small className="mr-1">PVP: <FormatCurrency value={item.pvp} showMinus /></small>

//                 <small className="mr-1">PVA: <FormatCurrency value={item.pva} /></small>

//                 <small className="mr-1">IVA: <FormatPercentage value={item.taxaIva} /></small>

//                 {item.stock.grupo > 0 && <small className="mr-1">Grupo: {item.stock.grupo}</small>}

//                 {item.pvpSemIva > 0 && <small className="mr-1">Rentab: <FormatCurrency value={calcRentabilidade(item.artigo, item.condicoes, fastTrackItem ? fastTrackItem.quantidade : 1, item.pva, item.pvpSemIva)} showMinus className="text-success text-bold-600" /></small>}

//                 <span className="info">{calcStockProximoPeriodo(fastTrackItem ? fastTrackItem.quantidade : 1, item.stock.primario, item.stock.secundario)}</span>
//             </div>
//         </div>
//         <div className="meta-container d-flex flex-column flex-sm-row align-middle">
//             <h4 className="item-pvf">PVF: <FormatCurrency value={calcPVF(item.artigo, item.condicoes, fastTrackItem ? fastTrackItem.quantidade : 1, item.pva)} /></h4>
//             <NumericInput
//                 className="form-control item-quantidade-input mx-auto"
//                 min={0}
//                 max={item.stock.primario + item.stock.secundario}
//                 value={fastTrackItem && fastTrackItem.quantidade}
//                 onChange={handleChange}
//                 noStyle
//                 placeholder="Qtd."
//                 onKeyDown={(e) => props.onKeyDown(e, item)}
//                 disabled={!item.stock.disponivel}
//             />
//         </div>
//     </div>
// </div>