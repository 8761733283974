import findIndex from 'lodash/findIndex'

const initialState = {
    open: false,
    showResumo: false,
    showConfirmation: false,
    isSubmitting: false,
    form: {
        query: '',
        fornecedores: [],
        promocaoId: 0,
        offset: 0,
        rows: 40
    },
    cart: [],
    data: []
}

const fastTrack = (state = initialState, action) => {
    switch (action.type) {

        case "FT_OPEN":
            return {
                ...state,
                open: true
            }

        case "FT_CLOSE":
            return {
                ...initialState,
                cart: state.cart
            }

        case "FT_SHOW_RESUMO":
            return {
                ...state,
                showResumo: action.payload
            }

        case "FT_SHOW_CONFIRMATION":
            return {
                ...state,
                showConfirmation: action.payload
            }

        case "FT_IS_SUBMITTING":
            return {
                ...state,
                isSubmitting: action.payload
            }

        case "FT_FORM_SET_PARCEIRO":
            return {
                ...state,
                form: {
                    ...state.form,
                    fornecedores: [action.payload]
                }
            }

        case "FT_SET_DATA":
            return {
                ...state,
                ...action.payload,
                showResumo: false
            }

        case "FT_ADD_DATA":
            return {
                ...state,
                data: [...state.data, ...action.payload],
                form: {
                    ...state.form,
                    offset: state.form.offset + state.form.rows
                }
            }

        case "FT_SET_SEARCH_KEY":
            return {
                ...state,
                form: {
                    ...state.form,
                    query: action.payload
                }
            }

        case 'FT_UPSERT_ITEM': {
            let index = findIndex(state.cart, ['artigo', action.payload.artigo])

            if (index > -1) {
                const newCart = state.cart.map(obj =>
                    obj.artigo === action.payload.artigo ? action.payload : obj
                )

                return {
                    ...state,
                    cart: [...newCart]
                }
            }
            else {
                return {
                    ...state,
                    cart: [...state.cart, action.payload]
                }
            }
        }

        case 'FT_DELETE_ITEM': {
            return {
                ...state,
                cart: state.cart.filter(o => o.artigo !== action.payload)
            }
        }

        case 'FT_DELETE_ALL':
            return {
                ...state,
                cart: []
            }

        case 'FT_ORDER_SUBMITTED':
            return {
                ...initialState,
                open: true,
                showResumo: false,
                showConfirmation: true,
                isSubmitting: false
            }

        default:
            return state
    }
}

export default fastTrack