import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptcha(props) {
    return (
        <ReCAPTCHA
            sitekey="6LfegLgZAAAAADqbqWJHhRDqubeyQViq-JBN3mfb"
            onChange={props.onVerify}
        />
    )
}
ReCaptcha.propTypes = {
    onVerify: PropTypes.func
}

// recaptcha keys
// 'public_key'     => env('RECAPTCHA_PUBLIC_KEY', '6LfakYYUAAAAAOkWLJZQbxgJp7H_TF0Ei84weqrn'),
// 'private_key'    => env('RECAPTCHA_PRIVATE_KEY', '6LfakYYUAAAAAFSvS5zdKULRqRo4e7WHOU-0wRLn'),

// official recaptcha keys
// public >= 6LfegLgZAAAAADqbqWJHhRDqubeyQViq-JBN3mfb
// backend => 6LfegLgZAAAAABnnsHXUwwwlvw8M2CUs2SyAG1ep
// test key => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI