import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import carrinho from './clientes/carrinho'
import fastTrack from './fast-track/fast-track'
import produtos from './produtos/produtos'

const rootReducer = combineReducers({
    customizer: customizer,
    auth: auth,
    navbar: navbar,
    carrinho: carrinho,
    fastTrack: fastTrack,
    produtos: produtos
})

export default rootReducer
