import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format';
import { Minus } from 'react-feather'

function FormatCurrency(props) {

    if (props.showMinus && props.value === 0) {
        return <Minus size={14} />
    }

    return (
        <NumberFormat
            prefix={props.prefix ? "€ " : ""}
            decimalSeparator={","}
            thousandSeparator={"."}
            fixedDecimalScale={props.decimalScale ? true : false}
            decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
            displayType="text"
            value={props.value}
            className={props.className}
        />
    )
}

FormatCurrency.defaultProps = {
    prefix: true,
    decimalScale: 2,
    showMinus: false
};

FormatCurrency.propTypes = {
    value: PropTypes.any,
    prefix: PropTypes.bool,
    decimalScale: PropTypes.number,
    showMinus: PropTypes.bool,
    className: PropTypes.string
}

export default FormatCurrency

