import React from "react"
import {
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap"

function ComercialAssociado(props) {
    const comercialData = {
        nome: 'Luís Aleixo',
        contacto: '931 000 456'
    }

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name text-bold-600">
                        {comercialData.nome}
                    </span>
                    <span className="user-status">{comercialData.contacto}</span>
                </div>
                <span data-tour="user">
                    <img
                        src={props.userImg}
                        className="round"
                        height="40"
                        width="40"
                        alt="avatar"
                    />
                </span>
            </DropdownToggle>
        </UncontrolledDropdown>
    )
}

ComercialAssociado.propTypes = {

}

export default ComercialAssociado

