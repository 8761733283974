import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ApiError } from '@api/api';

const useFetch = <T>(callback: () => Promise<T>, dependencyArr: any[] = [], errorToast = true) => {
    const [data, setData] = useState<T | undefined | null>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ApiError | undefined | null | any>(null);

    useEffect(() => {
        // const source = axios.CancelToken.source();
        callback()
            .then(response => {
                setLoading(false);
                setData(response);
                setError(undefined);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
                if (errorToast) {
                    toast.error(err?.message ?? 'Não foi possível processar o pedido');
                }
            });
        // return () => {
        //     source.cancel();
        // };
    }, dependencyArr);

    return { data, loading, error };
};

export default useFetch;
