import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    UncontrolledTooltip
} from "reactstrap"
import * as Icon from "react-feather"
import classnames from "classnames"
import 'assets/scss/pages/custom-components.scss'
import 'assets/scss/pages/product-fasttrack.scss'
// import ProdutosFastTrackAutoComplete from "./ProdutosFastTrackAutoComplete"
import ProdutoFastTrackItem from './ProdutoFastTrackItem'
import { useDebounce, useOnClickOutside } from '@components'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component';
import "assets/scss/pages/product-infinite-scroll.scss"

import { useDispatch, useSelector } from 'react-redux'
import * as fasttrackActions from "redux/actions/fast-track/fast-trackActions"
import FormatCurrency from '@components/common/FormatCurrency'
import SweetAlert from 'react-bootstrap-sweetalert';
import calcPVF from '@components/common/calcPVF'

const tipoDescDescription = {
    M: "Magium",
    G: "Grupo",
    T: "Tripartido",
    E: "Empifarma",
    "M+": "Magium Plus"
}

function ProdutoFastTrack(props) {
    const { form, data, open, cart, showResumo, showConfirmation, isSubmitting } = useSelector(({ fastTrack }) => fastTrack)
    const dispatch = useDispatch()
    const [searchKey, setSearchKey] = useState('')

    const inputEl = useRef(null);
    const containerRef = useRef();

    const searchKeyDebounced = useDebounce(searchKey, 300);

    // SET LISTENER CONTAINER
    useEffect(() => {
        containerRef.current.addEventListener("keydown", handleKeyDown)

        return () => {
            containerRef.current.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    useEffect(() => {
        if (open) {
            dispatch(fasttrackActions.setData({
                ...form,
                query: searchKeyDebounced,
                fornecedores: []
            }))
        }
    }, [searchKeyDebounced])

    useOnClickOutside(containerRef, () => {
        if (open) {
            dispatch(fasttrackActions.close())
            setSearchKey('')
        }
    });

    const handleForm = (prop, value) => {
        setSearchKey(value)
    }

    const handleNavbarSearch = (e) => {
        // e.stopPropagation()

        if (open) {
            dispatch(fasttrackActions.close())
            setSearchKey('')
        }
        else {
            dispatch(fasttrackActions.open())
        }
    }

    const handleKeyDown = (e) => {
        // ENTER => SE RESUMO ABERTO => SUBMETE ENCOMENDA
        // if (e.keyCode === 13 && showResumo) {
        //     dispatch(fasttrackActions.showConfirmation(true))
        // }

        // ENTER => MOSTRA RESUMO
        if (e.keyCode === 13 && !showResumo) {
            dispatch(fasttrackActions.showResumo(true))
            inputEl.current.focus()
        }

        // ESCAPE => FECHA RESUMO
        if (e.keyCode === 27) {
            dispatch(fasttrackActions.showResumo(false))
            inputEl.current.focus()
        }
    }

    const handleKeyDownLinha = (e, item) => {
        // DEL/BACKSPACE => REMOVE LINHA
        if (e.keyCode == 46) {
            dispatch(fasttrackActions.upsert({
                ...item,
                quantidade: 0
            }))
        }
    }

    const onItemQuantityChange = (item, quantidade) => {
        dispatch(fasttrackActions.upsert({
            ...item,
            quantidade
        }))
    }

    const onScrollDown = () => {
        dispatch(fasttrackActions.addData())
    }

    const handleShowCart = () => {
        dispatch(fasttrackActions.showResumo(!showResumo))
    }

    const handleConfirmation = () => {
        dispatch(fasttrackActions.showConfirmation(!showConfirmation))
    }

    const handleSubmit = () => {
        dispatch(fasttrackActions.submit())
    }

    const handleClearCart = () => {
        dispatch(fasttrackActions.deleteAllFromCart())
    }

    return (
        <div
            ref={containerRef}
            className={classnames("search-input", {
                open: open
                // "d-none": open === false
            })}>
            <div className={classnames("search-input-icon", { "d-none": open })}>
                <Icon.Search size={17} className="primary" />
            </div>

            <div id="vx-autocomplete-container" className="vx-autocomplete-container">
                <input
                    ref={inputEl}
                    className={`vx-autocomplete-search input form-control`}
                    placeholder="Produtos ..."
                    // onFocus={() => props.handleAppOverlay("1234")}
                    value={searchKey}
                    // autoFocus={true}
                    onChange={evt => handleForm('query', evt.target.value)}
                    onClick={() => dispatch(fasttrackActions.open())}
                />

                <div
                    id="scrollableDiv"
                    // className="suggestions-list product-fast-track"
                    className={classnames("suggestions-list product-fast-track search-input", {
                        "d-none": open === false
                    })}
                    style={{ overflow: "auto", top: "5rem" }}
                >
                    {
                        showResumo ?
                            (
                                <InfiniteScroll
                                    dataLength={cart.length}
                                    hasMore={true}
                                    next={onScrollDown}
                                    // height={450}
                                    scrollableTarget="scrollableDiv"
                                    style={{
                                        overflowX: 'hidden'
                                    }}
                                    // onScroll={onScrollDown}
                                    className={classnames("")}
                                // scrollThreshold={0.5}
                                // children={bool}
                                >
                                    {
                                        cart.map((prd, i) =>
                                            <ProdutoFastTrackItem
                                                key={i}
                                                item={prd}
                                                onChange={onItemQuantityChange}
                                                onKeyDown={handleKeyDownLinha}
                                                fastTrackItem={prd}
                                                tipoDescDescription={tipoDescDescription}
                                            // filteredData={filteredData}
                                            // activeSuggestion={activeSuggestion}
                                            // onSuggestionItemClick={onSuggestionItemClick}
                                            // onSuggestionItemHover={onSuggestionItemHover}
                                            // fastTrackItem={find(fastTrack, ['artigo', item.artigo])}
                                            />
                                        )
                                    }
                                </InfiniteScroll>
                            )
                            :
                            (
                                <InfiniteScroll
                                    dataLength={data.length}
                                    hasMore={true}
                                    next={onScrollDown}
                                    // height={450}
                                    scrollableTarget="scrollableDiv"
                                    style={{
                                        overflowX: 'hidden'
                                    }}
                                    // onScroll={onScrollDown}
                                    className={classnames("")}
                                // scrollThreshold={0.5}
                                // children={bool}
                                >
                                    {
                                        data.map((prd, i) =>
                                            <ProdutoFastTrackItem
                                                key={i}
                                                item={prd}
                                                onKeyDown={handleKeyDownLinha}
                                                onChange={onItemQuantityChange}
                                                fastTrackItem={_.find(cart, ['artigo', prd.artigo])}
                                                tipoDescDescription={tipoDescDescription}
                                            // filteredData={filteredData}
                                            // activeSuggestion={activeSuggestion}
                                            // onSuggestionItemClick={onSuggestionItemClick}
                                            // onSuggestionItemHover={onSuggestionItemHover}
                                            // fastTrackItem={find(fastTrack, ['artigo', item.artigo])}
                                            />
                                        )
                                    }
                                </InfiniteScroll>
                            )
                    }

                </div>

            </div>


            {/* <div className="search-input-hints">
                <Icon.CornerDownLeft size={14} /> confirmar
                <Icon.SkipBack size={14} className="ml-1" /> esc
            </div> */}

            <div className="search-input-encomendar">
                {
                    showResumo ?
                        (
                            <>
                                <span className="mr-1">Total: <FormatCurrency value={_.sumBy(cart, (o) => calcPVF(o.artigo, o.condicoes, o.quantidade, o.pva) * o.quantidade)} /></span>
                                <Button.Ripple
                                    id="cart-clear"
                                    outline
                                    color=""
                                    size="sm"
                                    onClick={() => handleClearCart()}
                                >
                                    <Icon.Trash size={18} />
                                </Button.Ripple>
                                <UncontrolledTooltip placement="bottom" target="cart-clear">Limpar</UncontrolledTooltip>
                                {cart?.length > 0 &&
                                    <>
                                        <Button.Ripple
                                            id="cart-submit"
                                            outline
                                            color="success"
                                            size="sm"
                                            className="search-input-submit-button"
                                            onClick={() => handleSubmit()}
                                            disabled={isSubmitting}
                                        >
                                            <Icon.Check size={18} />
                                        </Button.Ripple>
                                        <UncontrolledTooltip placement="bottom" target="cart-submit">Submeter</UncontrolledTooltip>
                                    </>
                                }
                            </>
                        )
                        :
                        (
                            <>
                                <Button.Ripple
                                    id="cart-show"
                                    outline
                                    color={(!cart || cart.length === 0) ? "" : "primary"}
                                    size="sm"
                                    disabled={!cart || cart.length === 0}
                                    onClick={() => handleShowCart()}
                                >
                                    <Icon.ShoppingCart size={18} />
                                </Button.Ripple>
                                <UncontrolledTooltip placement="bottom" target="cart-show">Ver resumo</UncontrolledTooltip>
                            </>
                        )
                }

            </div>

            <div className="search-input-close">
                <Icon.X
                    size={24}
                    onClick={handleNavbarSearch}
                />
            </div>

            <SweetAlert
                success
                title="Encomenda Submetida!"
                confirmBtnBsStyle="success"
                show={showConfirmation}
                onConfirm={() => handleConfirmation()}
            // onCancel={() => handleAlert([false, false])}
            >
                <p>Veja os detalhes na sua secção de documentos</p>
            </SweetAlert>
        </div>
    )
}

ProdutoFastTrack.propTypes = {

}

export default ProdutoFastTrack




// {/* <ProdutosFastTrackAutoComplete
//     className="form-control"
//     suggestions={state.suggestions}
//     filterKey="descricao"
//     filterHeaderKey="groupTitle"
//     grouped={false}
//     placeholder="Pesquisa Instantânea de Artigos..."
//     autoFocus={true}
//     clearInput={state.navbarSearch}
//     externalClick={e => {
//         setState({ ...state, navbarSearch: false, suggestions: [] })
//     }}
//     onChange={(evt) => handleChange(evt.target.value)}
//     // onKeyDown={e => {
//     //     if (e.keyCode === 27 || e.keyCode === 13) {
//     //         setState({
//     //             ...state,
//     //             navbarSearch: false
//     //         })
//     //         props.handleAppOverlay("")
//     //     }
//     // }}
//     customRender={(
//         item,
//         i,
//         filteredData,
//         activeSuggestion,
//         onSuggestionItemClick,
//         onSuggestionItemHover,
//     ) => <ProdutoFastTrackItem
//             key={i}
//             item={item}
//             i={i}
//             filteredData={filteredData}
//             activeSuggestion={activeSuggestion}
//             onSuggestionItemClick={onSuggestionItemClick}
//             onSuggestionItemHover={onSuggestionItemHover}
//             fastTrackItem={find(fastTrack, ['artigo', item.artigo])}
//         />}
//     onSuggestionsShown={userInput => {
//         if (state.navbarSearch) {
//             props.handleAppOverlay(userInput)
//         }
//     }}
// /> */}

// API.produtos().consulta({ ...formDebounced, offset: 0, rows: 20 })
//                 .then(res => {
//                     setState({
//                         ...state,
//                         data: res.data || [],
//                         isLoading: false
//                     })
//                     setPaging({
//                         offset: 0,
//                         rows: 20
//                     })
//                 })
//                 .catch(err => console.log(err))