import { find } from "lodash";

export default function calcPVF(a: any, c: any, q: any): number {
    try {
        q = q > 9999 ? 9999 : q;
        c = find(c, (i) => q >= i.bonificacao[0] && q <= i.bonificacao[1]);
        return c.fee;
    } catch (e) {
        console.log(e, a, c, q);
        return 0;
    }
}
