import React from 'react';
import { Button, CustomInput, FormGroup, Label } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import api from '@api/v2';
import * as Yup from 'yup';
import { UpdateUserCommandV2, IUserVmV2, PerfilDirecaoTecnicaFormV2 } from '@api/v2/api';
import { toast } from 'react-toastify';
import { GrupoSearchBox, RoleCheck } from '@components';
import { ROLES, TIPO_ENTIDADE } from '@components/consts';
import EntidadeDefaultSearchBox from '@components/common/EntidadeDefaultSearchBox';
import FornecedorSearchBox from '../common/FornecedorSearchBox';
import ClienteSearchBox from '../common/ClienteSearchBox';

interface Props {
    user: IUserVmV2;
}

const formSchema = Yup.object().shape({
    password: Yup.string().matches(
        /^(?=.*\d)[A-Za-z\d#@$!%*?&._-]{9,}$/,
        'Password não cumpre com os requisitos: Mínimo 9 carateres; Pelo menos um número; '
    ),
    perfilDt: Yup.bool().default(false),
    perfilDtNome: Yup.string().when('perfilDt', {
        is: true,
        then: Yup.string().required('Obrigatório')
    }),
    perfilDtCarteiraProf: Yup.string().when('perfilDt', {
        is: true,
        then: Yup.string().required('Obrigatório')
    }),
    perfilDtEmail: Yup.string()
        .email('Endereço de email com formato inválido')
        .when('perfilDt', {
            is: true,
            then: Yup.string().required('Obrigatório')
        })
});

const UserUpdateForm = ({ user }: Props) => {
    return (
        <Formik
            initialValues={{
                password: '',
                entidadeId: user.entidade?.id || '',
                tipoEntidade: user.entidade?.tipo || '',
                perfilDt: !!user.perfilDirTecnica,
                perfilDtNome: user.perfilDirTecnica?.nome || '',
                perfilDtCarteiraProf: user.perfilDirTecnica?.carteiraProfissional || '',
                perfilDtEmail: user.perfilDirTecnica?.email || '',
                perfilDtIsFarmResp: user.perfilDirTecnica?.tipo === 'FarmaceuticoResponsavel' || false
            }}
            onSubmit={values => {
                if (user.id) {
                    api.users_Update(
                        user.id,
                        new UpdateUserCommandV2({
                            entidadeId: values.entidadeId,
                            password: values.password,
                            perfilDirecaoTecnica: values.perfilDt
                                ? new PerfilDirecaoTecnicaFormV2({
                                      nome: values.perfilDtNome,
                                      email: values.perfilDtEmail,
                                      carteiraProfissional: values.perfilDtCarteiraProf,
                                      isFarmaceuticoResponsavel: values.perfilDtIsFarmResp
                                  })
                                : undefined
                        })
                    )
                        .then(() => window.location.reload())
                        .catch(err => {
                            console.log(err);
                            toast.error(err?.message ?? 'Não foi possível processar o pedido');
                        });
                }
            }}
            validationSchema={formSchema}
        >
            {({ values, touched, errors, handleChange, setFieldValue }) => {
                return (
                    <Form>
                        <FormGroup>
                            <Label>Password</Label>
                            <Field
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Nova Password (opcional)"
                                className={`form-control ${errors.password && touched.password && 'is-invalid'}`}
                            />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </FormGroup>

                        <RoleCheck roles={ROLES.PrivilegedOnly}>
                            <FormGroup>
                                <Label>Entidade</Label>
                                {values.tipoEntidade === TIPO_ENTIDADE.CLIENTE && (
                                    <ClienteSearchBox onChange={e => setFieldValue('entidadeId', e.value)} />
                                )}
                                {values.tipoEntidade === TIPO_ENTIDADE.GRUPO && (
                                    <GrupoSearchBox onChange={e => setFieldValue('entidadeId', e.value)} />
                                )}
                                {values.tipoEntidade === TIPO_ENTIDADE.FORNECEDOR && (
                                    <FornecedorSearchBox onChange={e => setFieldValue('entidadeId', e.value)} />
                                )}
                                {values.tipoEntidade === TIPO_ENTIDADE.DEFAULT && (
                                    <EntidadeDefaultSearchBox onChange={e => setFieldValue('entidadeId', e.value)} />
                                )}
                            </FormGroup>
                        </RoleCheck>

                        {values.tipoEntidade === 'CLIENTE' && (
                            <>
                                <hr />

                                <div className="d-flex justify-content-between">
                                    <h5>Perfil Dir. Técnica/Farm. Responsável (opcional)</h5>
                                    <Button
                                        size="sm"
                                        color={`flat-${values.perfilDt ? 'danger' : 'success'}`}
                                        onClick={() => setFieldValue('perfilDt', !values.perfilDt)}
                                    >
                                        {values.perfilDt ? 'Remover' : 'Adicionar'}
                                    </Button>
                                </div>

                                {values.perfilDt && (
                                    <>
                                        <FormGroup>
                                            <Label>Nome</Label>
                                            <Field
                                                id="perfilDtNome"
                                                name="perfilDtNome"
                                                type="text"
                                                placeholder="Nome"
                                                className={`form-control ${errors.perfilDtNome && 'is-invalid'}`}
                                            />
                                            <ErrorMessage name="perfilDtNome" component="div" className="text-danger" />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Field
                                                id="perfilDtEmail"
                                                name="perfilDtEmail"
                                                type="email"
                                                placeholder="Email"
                                                className={`form-control ${errors.perfilDtEmail && 'is-invalid'}`}
                                            />
                                            <ErrorMessage
                                                name="perfilDtEmail"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Nº Carteira Profissional</Label>
                                            <Field
                                                id="perfilDtCarteiraProf"
                                                name="perfilDtCarteiraProf"
                                                type="text"
                                                placeholder="Nº Carteira Profissional"
                                                className={`form-control ${
                                                    errors.perfilDtCarteiraProf && 'is-invalid'
                                                }`}
                                            />
                                            <ErrorMessage
                                                name="perfilDtCarteiraProf"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <CustomInput
                                                id="perfilDtIsFarmResp"
                                                name="perfilDtIsFarmResp"
                                                type="switch"
                                                checked={values.perfilDtIsFarmResp}
                                                onChange={handleChange}
                                                className="d-block mb-2"
                                                inline
                                            >
                                                <Label>Farmacêutico Responsável?</Label>
                                            </CustomInput>
                                        </FormGroup>
                                    </>
                                )}
                            </>
                        )}

                        <hr />

                        <Button color="primary" type="submit" className="float-right">
                            Submeter
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default React.memo(UserUpdateForm);
