import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

interface Props extends React.HTMLAttributes<HTMLElement> {
    blocking: boolean;
    className?: string;
}

const GrowingSpinnerContentLoader = (props: Props) => {
    if (props.blocking) {
        return (
            <div className={classnames('text-center', props.className)}>
                <Spinner type="grow" color="primary" />
            </div>
        );
    } else {
        return <React.Fragment>{props.children}</React.Fragment>;
    }
};

export default GrowingSpinnerContentLoader;
