import axios from 'axios';
import { EventEmitter } from '../../@components'
import api from '@api'

class authService extends EventEmitter {

    init() {
        this.handleAuthentication();
    }

    handleAuthentication = () => {

        let access_token = this.getAccessToken();

        if (!access_token) {
            this.emit('onNoAccessToken');
            return;
        }

        if (access_token) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        }
        else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    login = (username, password) => {

        return new Promise((resolve, reject) => {
            api.account_Login({ username, password })
                .then(data => {
                    let token = data.token
                    let user = {
                        id: data.id,
                        username: data.userName,
                        roles: data.roles,
                        nome: data.nome,
                        idInterno: data.idInterno,
                        nif: data.nif,
                        navBarUserInfo: data.navBarUserInfo
                    }

                    this.setSession(token)
                    resolve(user)
                })
                .catch(err => reject(err))
        })
    }

    profile = () => {
        return new Promise((resolve, reject) => {
            api.account_Profile()
                .then(data => resolve({
                    id: data.id,
                    username: data.userName,
                    roles: data.roles,
                    nome: data.nome,
                    idInterno: data.idInterno,
                    nif: data.nif,
                    navBarUserInfo: data.navBarUserInfo
                }))
                .catch(() => {
                    this.emit('onAutoLogout');
                })
        });
    }

    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        }
        else {
            localStorage.removeItem('jwt_access_token');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null);
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };
}


const instance = new authService();
export default instance;