import { set, unset } from 'lodash'

const initialState = {}

const carrinho = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CARRINHO":
            return action.payload
        case "UPDATE_CARRINHO":
            {
                let updatedState = { ...state, [action.payload.artigo]: action.payload.quantidade }
                // set(state, action.payload.artigo, action.payload.quantidade)
                return updatedState;
            }
        case "DELETE_CARRINHO":
            {
                let updatedState = state
                unset(updatedState, action.payload.artigo)
                return updatedState
            }
        case "CLEAN_CARRINHO":
            return {}
        default:
            return state
    }
}

export default carrinho
