import api from "@api";
import { CreateTrackingCommand } from "@api/api";

class tracking {
    static produtosFilter(payload: any): void {
        api.common_Tracking_Add(new CreateTrackingCommand({ id: "TRACKING_PRODUTOS_FILTER", payload }));
    }

    static addToCart(artigo: string, quantidade: number): void {
        api.common_Tracking_Add(new CreateTrackingCommand({ id: "TRACKING_PRODUTOS_ADD_TO_CART", payload: { artigo, quantidade } }));
    }

    static removeFromCart(artigo: string): void {
        api.common_Tracking_Add(new CreateTrackingCommand({ id: "TRACKING_PRODUTOS_REMOVE_FROM_CART", payload: artigo }));
    }

    static promocaoClick(id: number): void {
        api.common_Tracking_Add(new CreateTrackingCommand({ id: "TRACKING_PROMOCAO_CLICK", payload: id }));
    }

    static compreNovamenteClick(artigo: string): void {
        api.common_Tracking_Add(new CreateTrackingCommand({ id: "TRACKING_COMPRE_NOVAMENTE_CLICK", payload: artigo }));
    }
}

export default tracking;
