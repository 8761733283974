function sumArrayOfArray(array: number[][]) {
    return array.reduce(
        (
            a,
            b // replaces two elements in array by sum of them
        ) =>
            a.map(
                (
                    x,
                    i // for every `a` element returns...
                ) =>
                    x + // its value and...
                    (b[i] || 0) // corresponding element of `b`,
                // if exists; otherwise 0
            )
    );
}

export default { sumArrayOfArray };
