import React, { memo } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

import { FormGroup } from 'reactstrap';
import { Calendar } from 'react-feather';
import { format } from 'date-fns';
import classnames from 'classnames';

import 'flatpickr/dist/themes/light.css';
import 'assets/scss/plugins/forms/flatpickr/flatpickr.scss';

interface Props {
    onChangeRange(dates: string[]): void;
}

const DateRange = (props: Props & DateTimePickerProps) => {
    return (
        <FormGroup
            className={classnames('position-relative has-icon-left my-0', props.className)}
            style={{ width: '220px' }}
        >
            <Flatpickr
                {...props}
                className="form-control h-100"
                options={{
                    ...props.options,
                    mode: 'range',
                    locale: Portuguese,
                    dateFormat: 'Y-m-d'
                }}
                onChange={date => props.onChangeRange(date.map(v => format(v, 'yyyy-MM-dd')))}
            />
            <div className="form-control-position">
                <Calendar size={15} />
            </div>
        </FormGroup>
    );
};

export default memo(DateRange);
