import React, { memo } from 'react';
import { ArrowUp, ArrowDown } from 'react-feather';
import FormatPercentage from './FormatPercentage';

interface Props {
    value: any;
}

const PercentageGrowth = ({ value }: Props) => {
    return (
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="font-small-3 text-bold-600">
                <FormatPercentage value={value} showMinus />
            </div>
            {value < 0 && <ArrowDown size={14} className="danger" />}
            {value > 0 && <ArrowUp size={14} className="success" />}
        </div>
    );
};

export default memo(PercentageGrowth);
