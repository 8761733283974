import React from 'react'
import {
    UncontrolledDropdown,
    DropdownToggle,
    Badge
} from "reactstrap"
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import * as fasttrackActions from "redux/actions/fast-track/fast-trackActions"

function ShoppingCartCount() {
    const { cart } = useSelector(({ fastTrack }) => fastTrack)
    const dispatch = useDispatch()

    const handleClick = () => {
        if (cart && cart.length > 0) {
            dispatch(fasttrackActions.open())
            dispatch(fasttrackActions.showResumo(true))
        }
    }

    return (
        <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item mr-1"
        >
            <DropdownToggle tag="a" className="nav-link nav-link-label" onClick={handleClick}>
                <Icon.ShoppingCart size={21} />
                <Badge pill color="primary" className="badge-up">{cart && cart.length}</Badge>
            </DropdownToggle>
        </UncontrolledDropdown>
    )
}

export default ShoppingCartCount

