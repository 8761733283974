import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
    {
        id: 'home',
        title: 'Home',
        type: 'item',
        icon: <Icon.Home size={20} />,
        navLink: '/',
        collapsed: true
    },
    {
        id: 'c-produtos',
        title: 'Produtos',
        type: 'item',
        icon: <Icon.Search size={20} />,
        permissions: ['CLIENTE'],
        navLink: '/produtos',
        collapsed: true
    },
    {
        id: 'c-faq',
        title: 'FAQ',
        type: 'item',
        icon: <Icon.HelpCircle size={20} />,
        permissions: ['CLIENTE'],
        navLink: '/faq',
        collapsed: true
    },
    {
        id: 'c-sep-2',
        title: '',
        type: 'item',
        icon: <Icon.MoreHorizontal size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_DASHBOARD'],
        navLink: '#',
        collapsed: true
    },
    {
        id: 'c-dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: <Icon.BarChart size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_DASHBOARD'],
        navLink: '/dashboard',
        collapsed: true
    },
    {
        id: 'c-documentos',
        title: 'Documentos',
        type: 'collapse',
        icon: <Icon.FileText size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_DOCUMENTOS'],
        collapsed: true,
        children: [
            {
                id: 'c-documentos-encomendas',
                title: 'Encomendas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/encomendas',
                permissions: ['CLIENTE'],
                collapsed: true
            },
            {
                id: 'c-documentos-facturas',
                title: 'Facturas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/facturas',
                permissions: ['CLIENTE'],
                collapsed: true
            },
            {
                id: 'c-documentos-creditos',
                title: 'Créditos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/creditos',
                permissions: ['CLIENTE'],
                collapsed: true
            },
            {
                id: 'c-documentos-resumos',
                title: 'Resumos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/resumos',
                permissions: ['CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-documentos-recibos',
                title: 'Recibos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/recibos',
                permissions: ['CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-documentos-conta-corrente',
                title: 'Conta Corrente',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/conta-corrente',
                permissions: ['CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-documentos-requisicoes',
                title: 'Requisições',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/requisicoes',
                permissions: ['CLIENTE'],
                collapsed: true
            },
            {
                id: 'c-documentos-movimentos',
                title: 'Mov. Produtos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/documentos/movimentos',
                permissions: ['CLIENTE'],
                collapsed: true
            }
        ]
    },
    {
        id: 'c-reclamacoes',
        title: 'Serviço Pós-Venda',
        type: 'item',
        icon: <Icon.Tag size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_RECLAMACOES'],
        navLink: '/documentos/servico-pos-venda',
        collapsed: true
    },
    {
        id: 'c-devolucoes',
        title: 'Logística Inversa',
        type: 'item',
        icon: <Icon.Repeat size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_DEVOLUCOES'],
        navLink: '/documentos/logistica-inversa',
        collapsed: true
    },
    {
        id: 'c-grupo',
        title: 'Grupo',
        type: 'collapse',
        icon: <Icon.Users size={20} />,
        permissions: ['GRUPO'],
        collapsed: true,
        children: [
            {
                id: 'c-grupo-dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/dashboard',
                permissions: ['CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-grupo-entradas',
                title: 'Entradas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/entradas',
                permissions: ['CLIENTE_GRUPO', 'CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-grupo-saidas',
                title: 'Saídas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/saidas',
                permissions: ['CLIENTE_GRUPO', 'CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c-grupo-stocks',
                title: 'Stocks',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/stocks',
                permissions: ['CLIENTE_GRUPO', 'CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c_grupo_contacorrente',
                title: 'Conta Corrente',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/conta-corrente',
                permissions: ['GRUPO_SEP_CONTACORRENTE'],
                collapsed: true
            },
            {
                id: 'c_grupo_tripartidos',
                title: 'Tripartidos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/tripartidos',
                permissions: ['CLIENTE_GRUPO', 'CLIENTE_GESTOR'],
                collapsed: true
            },
            {
                id: 'c_grupo_reportvendas',
                title: 'Relatório Vendas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/grupo/report-vendas',
                permissions: ['CLIENTE_GRUPO', 'CLIENTE_GESTOR'],
                collapsed: true
            }
        ]
    },
    {
        id: 'c-distribuicao',
        title: 'Entregas',
        type: 'item',
        icon: <Icon.Truck size={20} />,
        permissions: ['CLIENTE_GESTOR', 'CLIENTE_SEP_ENTREGAS'],
        navLink: '/entregas',
        collapsed: true
    },

    // PARCEIROS
    {
        id: 'f-encomendas',
        title: 'Encomendas',
        type: 'item',
        icon: <Icon.Crosshair size={20} />,
        permissions: ['PARCEIRO'],
        navLink: '/encomendas',
        collapsed: true
    },
    {
        id: 'f-parametrizacoes',
        title: 'Parametrizações',
        type: 'collapse',
        icon: <Icon.Settings size={20} />,
        permissions: ['PARCEIRO'],
        collapsed: true,
        children: [
            {
                id: 'f-parametrizacoes-campanhas',
                title: 'Campanhas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/parametrizacoes/campanhas',
                permissions: ['PARCEIRO'],
                collapsed: true
            },
            {
                id: 'f-parametrizacoes-tripartidos',
                title: 'Tripartidos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/parametrizacoes/tripartidos',
                permissions: ['PARCEIRO'],
                collapsed: true
            }
        ]
    },
    {
        id: 'f-analytics',
        title: 'Analytics',
        type: 'collapse',
        icon: <Icon.PieChart size={20} />,
        permissions: ['PARCEIRO'],
        collapsed: true,
        children: [
            {
                id: 'f-analytics-artigos',
                title: 'Artigos',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/analytics/artigos',
                permissions: ['PARCEIRO'],
                collapsed: true
            },
            {
                id: 'f-analytics-clientes',
                title: 'Clientes',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/analytics/clientes',
                permissions: ['PARCEIRO'],
                collapsed: true
            },
            {
                id: 'f-analytics-vendas',
                title: 'Vendas',
                type: 'item',
                icon: <Icon.Circle size={10} />,
                navLink: '/analytics/vendas',
                permissions: ['PARCEIRO'],
                collapsed: true
            }
        ]
    },
    {
        id: 'f-stocks',
        title: 'Stocks',
        type: 'item',
        icon: <Icon.Layers size={20} />,
        permissions: ['PARCEIRO'],
        navLink: '/stocks',
        collapsed: true
    },
    {
        id: 'f-compensacoes',
        title: 'Compensações',
        type: 'item',
        icon: <Icon.FileMinus size={20} />,
        permissions: ['PARCEIRO'],
        navLink: '/compensacoes',
        collapsed: true
    },

    {
        id: 'c-sep-1',
        title: '',
        type: 'item',
        icon: <Icon.MoreHorizontal size={20} />,
        permissions: ['CLIENTE'],
        navLink: '#',
        collapsed: true
    },
    {
        type: 'groupHeader',
        groupTitle: 'LIGUE-NOS',
        permissions: ['CLIENTE', 'CLIENTE_GRUPO']
    },
    {
        id: 'c-liguenos',
        title: '239 499 010',
        type: 'item',
        icon: <Icon.PhoneCall size={20} />,
        permissions: ['CLIENTE', 'CLIENTE_GRUPO'],
        navLink: '#',
        collapsed: true
    }
];

export default navigationConfig;
