import { find } from 'lodash'

export default function calcPVF(a, c, q, p = 0, t = false) {
    try {
        q = q > 9999 ? 9999 : q
        c = find(c, (i) => (q >= i.bonificacao[0] && q <= i.bonificacao[1]))
        return (c.bonus > 0 ? (q * p) / (q + c.bonus) : c.precoUnitario) * (t ? q : 1)
    }
    catch (e) {
        console.log(e, a, c, q)
        return 0
    }
}