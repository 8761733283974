import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../../redux/actions/auth/loginActions';
import authService from './authService';
import Spinner from "../../components/@vuexy/spinner/Fallback-spinner"
import { history } from "../../history"
import { reject } from 'lodash';

class Auth extends Component {

    state = {
        waitAuthCheck: true
    }

    componentDidMount() {
        return Promise.all([
            this.authCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false })
        })
    }

    authCheck = () => new Promise(resolve => {

        authService.on('onAutoLogin', () => {
            authService.profile()
                .then(user => {
                    this.props.setUserData(user)
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject()
                })
        });

        authService.on('onAutoLogout', (message) => {
            this.props.logout();
            resolve();
        });

        authService.on('onNoAccessToken', () => {
            this.props.logout();
            resolve();
        });

        authService.init();

        return Promise.resolve();
    })

    render() {
        return this.state.waitAuthCheck ? <Spinner /> : <React.Fragment children={this.props.children} />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserData: loginActions.setUserData,
        logout: loginActions.logoutWithJWT
        // logout: userActions.logoutUser
        // setUserData: userActions.setUserData,
        // // setUserDataAuth0: userActions.setUserDataAuth0,
        // // setUserDataFirebase: userActions.setUserDataFirebase,
        // showMessage: Actions.showMessage,
        // hideMessage: Actions.hideMessage
    },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);