import _ from 'lodash'

const initialState = {
    loading: false,
    run: 0,
    data: []
}

const produtos = (state = initialState, action) => {
    switch (action.type) {
        case 'PRD_ADD': return { loading: true, data: _.orderBy([...state.data, ...action.payload], [(d) => d.descricao], ['asc']), run: state.run + 1 }
        case 'PRD_LOADING': return { ...state, loading: action.payload }
        case 'PRD_RESET': return initialState
        default: return state
    }
}

export default produtos