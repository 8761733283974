import { getYear, getMonth } from 'date-fns'

export default {
    mesOptions() {
        return [
            [1, "Janeiro"],
            [2, "Fevereiro"],
            [3, "Março"],
            [4, "Abril"],
            [5, "Maio"],
            [6, "Junho"],
            [7, "Julho"],
            [8, "Agosto"],
            [9, "Setembro"],
            [10, "Outubro"],
            [11, "Novembro"],
            [12, "Dezembro"]
        ]
    },

    mesActual() { return getMonth(new Date) + 1 },

    anoOptions(min = null, order = 'asc') {
        let anos = []
        let anoActual = getYear(new Date);

        if (min === null) min = anoActual - 1

        for (let i = min; i <= anoActual; i++) {
            anos.push(i)
        }

        if (order === 'desc') {
            anos.sort(function (a, b) { return b - a });
        }

        return anos
    },

    anoActual() { return getYear(new Date) },

    anoMinimoPermitido() { return getYear(new Date) - 3 }
}



