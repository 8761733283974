

export default function calcStockProximoPeriodo(quantidade, primario, secundario) {
    if (quantidade === 0) return

    quantidade -= primario >= quantidade ? quantidade : primario;

    if (quantidade > 0) {
        quantidade = secundario >= quantidade ? quantidade : secundario;

        if (quantidade > 0) {
            return `${quantidade} un. próx. período`
        }
    }
}